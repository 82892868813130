import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    axiosInstance,
    axiosInstanceNewUrl,
    axiosInstance_AUTH,
    axiosInstance_2,
    axiosInstance_ORCHAPI,
    axiosInstance_Patient_PartialPPAF,
    axiosInstanceSearch,
    axiosInstanceSurvey,
    axiosInstancePrescriber,
    axiosInstancePharmacy,
    axiosInstanceCognitoUrl,
    axiosInstance_AUTH_COGNITO,
    axiosInstanceSurveyUnsecure,
    axiosInstanceSearchUnsecure,
} from './../../constants/Config/axiosConfig';
import { getFormattedPhone } from 'utils/getFormattedPhone';
import { RoleByGroup } from 'constants/Config/appConfig';
import { AuthDataType } from 'pages/Pharmacy/logDispense/logDispenseType';
import { congitoResMapper, congitoSignUP_payload } from 'utils/congitoResMapper';
import { signIn, signUp, UpdateManagePassword, updateNonPrescSystemId, UpdatePassword } from 'utils/authService';
import indexedDBStore from 'store/indexedDBStore';
import { ProgramConfig, lenalidomide, pomalidomide, thalomid } from 'constants/Config/programsConfig';

const setlogMultipleDispenseinitialData = (): AuthDataType[] => {
    const logMultipleDispenseinitialData: AuthDataType[] = [];
    for (let i = 0; i < 20; i++)
        logMultipleDispenseinitialData.push({
            id: i + 1,
            checked: false,
            auth_no: { errorMsg: null, auth_no: '' },
            patient_name: { errorMsg: null, patient_name: '' },
            product: [
                {
                    id: 1,
                    checked: false,
                    product_id: { errorMsg: null, product_id: '' },
                    quantity: { errorMsg: null, quantity: '' },
                },
            ],
            authResponse: {},
            product_options: [],
            location: {},
        });
    return logMultipleDispenseinitialData;
};

const initialState: any = {
    userAccount: { message: '' },
    selectedProgram: {
        label: '',
        id: '',
        image: '',
        prescribing_info: '',
    },
    selectedPatientProgram: {},
    patientProgramList: {
        firstName: '',
        lastName: '',
        checked: '',
        relation: '',
    },
    patientChecklist: [],
    searchPatientDto: {},
    userInfo: {},
    isSubmitAndPrint: 'productSignUp',
    isAccount: 'createaccountconfirmation',
    PharmacyStore: {},
    patientInformation: {},
    patientSearchStore: {},
    patientPrescriberStore: {},
    prescriberSurveySearchStore: {},
    patientCamundaStore: [{}],
    patientReactivationStore: {},
    patientReactivationSpecialStore: {},
    activePharmacy: {},
    patientResponse: {},
    existingPatientRisk: null,
    passwordField: {
        password: '',
    },
    enrolledProducts: [''],
    accFormDetails: {
        firstName: '',
        lastName: '',
        officeAddress: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        userName: '',
        email: '',
        securityQuestion: '',
        securityAnswer: '',
    },
    productEnrolmentData: {
        mi: '',
        degree: '',
        specialty: '',
        number: '',
        officeName: '',
        attention: '',
        officeAddress: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        fax: '',
        email: '',
        officeName2: '',
        attention2: '',
        address2: '',
        city2: '',
        state2: '',
        zip2: '',
        phone2: '',
        fax2: '',
        email2: '',
        label: '',
        image: '',
    },
    role: 'prescriber',
    helpURL: 'prescriberHome',
    prescriberEnrollment: [''],
    prescriberUniqueID: '',
    isPrescriberIdNumValid: false,
    showPV0030Msg: false,
    prescriberHome: {
        help: '',
        header: [{ order: '', content: '' }],
        body: [
            {
                order: '',
                title: '',
                content: '',
                medicationLink: '',
                prescribingLink: '',
            },
        ],
        footer: '',
    } as any,
    isPPAF: false,
    prescriberDetails: {
        firstName: '',
        lastName: '',
        prescriberSystemId: '',
        pin: '',
        mi: '',
        addressList: [''],
        selectedAddress: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        ext: '',
        fax: '',
        addressId: '',
        officeName: '',
        attention: '',
        email: '',
        onlineUserid: '',
    },
    patientPINStatus: '',
    errorPrescriberHome: {},
    ppafContent: {},
    upatedHelpContentKey: '',
    errorCreateAccount: {
        message: null,
    },
    errorValidation: {
        message: '',
        errorMsg: '',
    },
    errorFindPrescriber: {
        message: '',
    },
    errorPINValidCheck: {
        message: '',
    },
    errorValidatePatient: {
        message: '',
    },
    popUpData: [],
    camundaInstance: [],
    patientId: '',
    activeEnrollments: [],
    patientSurvey: {
        prevNavigation: 0,
        localNavigation: 0,
        selectedSurveyProduct: {},
        eligiableProduct: {},
        updatedSurveyPayload: {},
        questionSequence: 1,
        conductSurveyResponse: {},
        businessErrorCode: 0,
        flowType: 1,
    },
    loginPage: false,
    associationData: {},
    pharmacyAuthenticate: false,
    patientData: [],
    logMultipleDispense: {
        authorizationsDetails: [],
        multipleDispensesTable: setlogMultipleDispenseinitialData(),
        createDispenseData: [],
        localNavigation: 1,
    },
    prescriptionFormData: {},
    prescriptionFormDetailsData: {},
    authorizationData: [],
    pharmacyLocation: {},
    activePharmacyLocation: [],
    prescriptionFormDosage: {},
    prescriptionAuthDetails: {},
    prescAllDetails: {},
    prescriptionSaveApi: {},
    selectedPatient: null,
    patientList: {},
    actionTypes: {},
    dispenseHistoryList: {},
    prescriptionPaylod: {},
    calendarData: [],
    actionitemInfo: {},
    prescriberDashboardFindPatientIsLoading: true,
    patientDataFromCalender: [],
    prescriberDashboardCalenderDynamicData: [],
    calendarActionItemPayload: {},
    surveyDataFromPatientDetails: {},
    surveyInfoFromRoute: {},
    isWritePrescription:false,
    loginHomePrescriber:false,
    loginHomePharmacy:false,
    patientIntent:'ext_new',
    patientSignedPPAf: false, 
    patientPPafFlow: "",
};

export const prescriberHomeThunk = createAsyncThunk('get/content', async (key: string) => {
    const response = {
        help: 'Help page content',
        header: [
            {
                order: 1,
                content: `To avoid embryo-fetal exposure, Risk Evaluation and Mitigation Strategy (REMS) programs are
                    mandatory for the REMS products ${thalomid.toUpperCase()}<span style="
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    ">®</span> 
                    (thalidomide), REVLIMID<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span> (${lenalidomide}), ${lenalidomide} and ${pomalidomide.toUpperCase()}<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span> (pomalidomide). The ${
                        ProgramConfig('rems_program_thalomid').title
                    }<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span> program, the ${
                        ProgramConfig('rems_program_lenalidomide').title
                    } program and the
                    ${
                        ProgramConfig('rems_program_pomalyst').title
                    }<sup>®</sup> program require prescribers to be certified and
                    comply with all of the requirements for each program. To begin the enrollment process, please choose
                    a program below.`,
            },
        ],
        body: [
            {
                order: 1,
                title: `For <span style="font-weight: bold;">${
                    ProgramConfig('rems_program_lenalidomide').title
                }</span>`,
                content: {
                    disclaimer1: `These highlights do not include all the information needed to use lenalidomide safely and effectively. Please see relevant `,
                    disclaimer2: `for ${lenalidomide}.`,
                    lists: {
                        title: `Risks of ${lenalidomide}`,
                        content: [
                            `${
                                ProgramConfig('rems_program_lenalidomide').label
                            } is similar to the medicine thalidomide (THALOMID<sup>®</sup>).
                            Thalidomide can cause severe life-threatening birth defects. If ${lenalidomide} is
                            used during pregnancy, it can cause birth defects or embryo-fetal death.
                            ${
                                ProgramConfig('rems_program_lenalidomide').label
                            } must not be used by pregnant females and females who are able to
                            get pregnant. Females who are able<br/>to get pregnant must avoid pregnancy while
                            taking ${lenalidomide}.`,
                            `${
                                ProgramConfig('rems_program_lenalidomide').label
                            } causes low white blood cells (neutropenia) and low platelets
                            (thrombocytopenia) in most patients.`,
                            `${
                                ProgramConfig('rems_program_lenalidomide').label
                            } causes a higher chance for blood clots in your arteries (heart
                                attacks and strokes), veins (deep vein thrombosis) and lungs (pulmonary<br/>
                                embolism). To reduce this increased risk, most people who take ${lenalidomide} will
                                also take a blood thinner medicine.`,
                        ],
                    },
                },
                prescriberInfoURL: ProgramConfig('rems_program_lenalidomide').prescribing_info,
                medicationURL: ProgramConfig('rems_program_lenalidomide').prescribing_info,
            },
            {
                order: 2,
                title: `For <span style="font-weight: bold;">${ProgramConfig('rems_program_pomalyst').title?.toUpperCase()}<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span></span>`,
                content: {
                    disclaimer1: `These highlights do not include all the information needed to use ${pomalidomide.toUpperCase()} safely and effectively. Please see `,
                    disclaimer2: `for ${pomalidomide.toUpperCase()}.`,
                    lists: {
                        title: `Risks of ${pomalidomide.toUpperCase()}`,
                        content: [
                            `${
                                ProgramConfig('rems_program_pomalyst').label.toUpperCase()
                            } is similar to the medicine thalidomide (${ProgramConfig(
                                'rems_program_thalomid'
                            ).label.toUpperCase()}<sup>®</sup>).
                            Thalidomide can cause severe life-threatening birth defects. If ${pomalidomide.toUpperCase()} is used
                            during pregnancy, it can cause<br/>birth defects or embryo-fetal death. ${
                                ProgramConfig('rems_program_pomalyst').label.toUpperCase()
                            }
                            must not be used by pregnant females and females who are able to get pregnant.
                            Females who are able to get pregnant must<br/>avoid pregnancy while taking ${pomalidomide.toUpperCase()}.`,
                            `Blood clots in your arteries (heart attacks and strokes), veins (deep vein thrombosis) and lungs (pulmonary embolism) can happen if you take ${pomalidomide.toUpperCase()}.`,
                        ],
                    },
                },
                prescriberInfoURL: ProgramConfig('rems_program_pomalyst').prescribing_info,
                medicationURL: ProgramConfig('rems_program_pomalyst').prescribing_info,
            },
            {
                order: 3,
                title: `For <span style="font-weight: bold;">${
                    ProgramConfig('rems_program_thalomid').title
                }<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span></span>`,
                content: {
                    disclaimer1: `These highlights do not include all the information needed to use ${ProgramConfig(
                        'rems_program_thalomid'
                    ).label.toUpperCase()} safely and effectively. Please see `,
                    disclaimer2: `for ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}.`,
                    lists: {
                        title: `Risks of ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}`,
                        content: [
                            `If ${ProgramConfig(
                                'rems_program_thalomid'
                            ).label.toUpperCase()} is used during pregnancy, it can cause birth defects or embryo-fetal
                            death. ${ProgramConfig(
                                'rems_program_thalomid'
                            ).label.toUpperCase()} must not be used by pregnant females and females who are able to
                            get pregnant. Females who are able to get pregnant must avoid pregnancy while
                            taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}.`,
                            `<p style="margin-top:0px;">
                            ${ProgramConfig(
                                'rems_program_thalomid'
                            ).label.toUpperCase()} causes a higher<br/>chance for blood clots in your veins (deep vein
                            thrombosis) and lungs (pulmonary embolism).
                        </p>
                        <p>
                        ${ProgramConfig(
                            'rems_program_thalomid'
                        ).label.toUpperCase()} in combination with dexamethasone is indicated for the treatment of
                            patients with newly diagnosed multiple myeloma (MM).
                        </p>
                        <p>
                        ${ProgramConfig(
                            'rems_program_thalomid'
                        ).label.toUpperCase()} is indicated for the acute treatment of the cutaneous
                            manifestations of moderate to severe erythema nodosum leprosum (ENL).
                        </p>
                        <p>
                        ${ProgramConfig(
                            'rems_program_thalomid'
                        ).label.toUpperCase()} is not indicated as monotherapy for such ENL treatment in the
                            presence of moderate to severe neuritis.
                        </p>
                        <p style="margin-bottom:0px;">
                        ${ProgramConfig(
                            'rems_program_thalomid'
                        ).label.toUpperCase()} is also indicated as maintenance therapy for prevention and
                            suppression of the cutaneous manifestations of ENL recurrence.
                        </p>`,
                        ],
                    },
                },
                prescriberInfoURL: ProgramConfig('rems_program_thalomid').prescribing_info,
                medicationURL: ProgramConfig('rems_program_thalomid').prescribing_info,
            },
        ],
    };
    return await response;
});

export const createUserAccount = createAsyncThunk('post/User', async (userPayload: any, { rejectWithValue }) => {
    const cognitoPayload = congitoSignUP_payload(userPayload);
    try {
        const response = await signUp(cognitoPayload);
        let authResult: any = null;
        if (response?.UserConfirmed) {
            authResult = await signIn(cognitoPayload?.userName || '', cognitoPayload?.password || '');
            sessionStorage.setItem('token', authResult?.AuthenticationResult.AccessToken);
        }
        return { response, authResult };
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

export const createUserAccountPharmacy = createAsyncThunk(
    'post/pharmacy',
    async (userPayload: any, { rejectWithValue }) => {
        const cognitoPayload = congitoSignUP_payload(userPayload);
        try {
            const response = await signUp(cognitoPayload);
            let authResult: any = null;
            if (response?.UserConfirmed) {
                authResult = await signIn(cognitoPayload?.userName || '', cognitoPayload?.password || '');
                sessionStorage.setItem('token', authResult?.AuthenticationResult.AccessToken);
            }
            return { response, authResult };
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const searchPatientSurvey = createAsyncThunk('searchPatientSurvey', async (payload: any) => {
    try {
        const response = await axiosInstanceSurveyUnsecure.post(`/v1/surveys/patient`, payload);
        return response;
    } catch (err: any) {
        return err;
    }
});
export const patientConductSurvey = createAsyncThunk('patientConductSurvey', async (payload: any) => {
    try {
        const response = await axiosInstanceSurveyUnsecure.post(`/v1/surveys/patient/conduct-survey`, payload);
        return response;
    } catch (err: any) {
        return err;
    }
});
export const patientEligibilityProduct = createAsyncThunk('patientEligibilityProduct', async (data: any) => {
    try {
        const { payload, productName } = data;
        // const response = await axiosInstanceSurveyUnsecure.post(`/v1/surveys/patient/${productName}`, payload);
        // /startPatientSurvey

        const response = await axiosInstanceSurveyUnsecure.post(`/v1/surveys/patient/${productName}`, payload);
        return response;
    } catch (err: any) {
        return err;
    }
});

export const checkUserExists = createAsyncThunk('post/CheckValidation', async (payload: any) => {
    try {
        // const response = await axiosInstanceNewUrl.post(`authentication-api/v1/validateUsername`, {
        const response = await axiosInstanceCognitoUrl.post(`/ValidateUsername`, {
            username: payload.userName,
        });
        return response.data;
    } catch (err: any) {
        return err.response.data.message;
    }
});

export const checkAssociation = createAsyncThunk('post/checkAssociation', async (payload: any, { rejectWithValue }) => {
    const response = await axiosInstancePrescriber.post(`/v1/prescriber/get-association-user-details`, payload);
    return response;
});

export const createAssociation = createAsyncThunk('post/createAssociation', async (payload: any) => {
    const response = await axiosInstancePrescriber.post(`/v1/prescriber/create-associations`, payload);
    return response;
});

export const checkAccountDetailsValidation = createAsyncThunk('post/CheckValidation', async (accDetails: any) => {
    const { values, stateId } = accDetails;
    const addressPayload = { zipCode: values.zipCode, city: values.city.toUpperCase(), state: +stateId };
    const { userName, ...userObj } = values;
    try {
        const response: any = await axiosInstanceSearchUnsecure.post(`/search-api/validate/address`, addressPayload);
        if (response.data?.message === 'Success') {
            try {
                // const response = await axiosInstanceNewUrl.post(`authentication-api/v1/validateUsername`, {
                const response = await axiosInstanceCognitoUrl.post(`/ValidateUsername`, {
                    username: userName,
                    group_tag: RoleByGroup.Prescriber,
                });
                return response.data;
            } catch (err: any) {
                return err.response.data.message;
            }
        } else {
            return response.response.data.data.errorMesssage.messageKey;
        }
    } catch (err: any) {
        return err.response.data.data.errorMesssage.messageKey;
    }
});

export const saveNonPrescriber = createAsyncThunk('post/saveNonPrescriber', async (payload: any) => {
    const response = await axiosInstancePrescriber.post('/v1/nonprescriber/save', payload);
    if(response)
    {
        try{
            const loginState = await indexedDBStore.getItem(`prescriberUser`);
            const token = loginState?.currentUser?.accessToken;
             await updateNonPrescSystemId(token,response?.data?.data?.nonPrescriberId);
        }
        catch(err:any){
            console.log(err);
        }
    }
    return response;
});



export const validateAndCreateOneTimePwd = createAsyncThunk(
    'post/ValidateAndCreateOneTimePwd',
    async (payload: { username: string; email: string; security_question: string; security_answer: string }) => {
        try {
            // const response = await axiosInstance_AUTH.post('/authentication/ValidateAndCreateOneTimePassword', payload);
            const response = await axiosInstance_AUTH_COGNITO.post('/ValidateUserDetails', payload);
            return response;
        } catch (err: any) {
            console.log(err);
            return err.response.data.data.errorMesssage.messageKey;
        }
    }
);

export const resetPassword = createAsyncThunk('post/resetPassword',
    async (payload: { username: string; email: string; security_question: string; security_answer: string }) => {
        try {
            // const response = await axiosInstance_AUTH.post('/authentication/ValidateAndCreateOneTimePassword', payload);
            const response = await axiosInstance_AUTH_COGNITO.post('/ValidateUserDetails', payload);
            return response;
        } catch (err: any) {
            console.log(err);
            return err.response.data.data.errorMesssage.messageKey;
        }
    }
);

export const resetPasswordDR = createAsyncThunk('post/resetPasswordDR',
    async (payload: { username: string; previouspassword: string,proposedpassword: string  }) => {
        try {
            const group = window.location.href?.includes('pharmacy') ? 'pharmacy' : 'prescriber';
            const loginState = await indexedDBStore.getItem(`${group}User`);
            const token = loginState?.currentUser?.accessToken;
            const response = await axiosInstance_AUTH_COGNITO.post('/resetpassword', payload,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
            );
            return response;
        } catch (err: any) {
            console.log(err);
            return err.response.data.data.errorMesssage.messageKey;
        }
    }
);

export const forgotPasswordDR = createAsyncThunk('post/forgotPassword',
    async (payload: { username: string; temppassword: string,newpassword: string  }) => {
        try {
            // const response = await axiosInstance_AUTH.post('/authentication/ValidateAndCreateOneTimePassword', payload);
            const response = await axiosInstance_AUTH_COGNITO.post('/setnewpassword', payload);
            return response;
        } catch (err: any) {
            console.log(err);
            return err.response.data.data.errorMesssage.messageKey;
        }
    }
);

export const updatePassword = createAsyncThunk(
    'post/updatePassword',
    async (
        payload: {
            username: string;
            temporarypassword: string;
            newpassword: string;
            // session: string,
            security_question: string;
            security_answer: string;
        },
        { rejectWithValue }
    ) => {
        try {
            //const response = await axiosInstance_AUTH.post('/updatePassword', payload);
            const response = await UpdatePassword(payload);
            return response;
        } catch (err: any) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

export const resetPasswordManage = createAsyncThunk(
    'post/resetPasswordManage',
    async (payload: { username: string; oldPassword: string; newpassword: string }, { rejectWithValue }) => {
        try {
            const response = await UpdateManagePassword(payload); //await axiosInstance_AUTH.post('/resetPrescriberPassword', payload);
            return response;
        } catch (err: any) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

export const resetPasswordManagePhar = createAsyncThunk(
    'post/resetPasswordManagePhar',
    async (payload: { username: string; oldPassword: string; newpassword: string }, { rejectWithValue }) => {
        try {
            const response = await UpdateManagePassword(payload); //await axiosInstance_AUTH.post('/reset-pharmacy-password', payload);
            return response;
        } catch (err: any) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

export const fetchCurrentPrescriber = createAsyncThunk('post/fetchCurrentPrescriber', async (payload: any) => {
    const response = await axiosInstancePrescriber.post(`/v1/prescriber/findprescriber`, payload);
    console.log(response);

    return response;
});

export const popUpNotification = createAsyncThunk('/acknowledgement/getNotification', async (payload: any) => {
    try {
        const response = await axiosInstance_ORCHAPI.post(
            `/acknowledgement/getNotification`,payload
        );
       
        return response;
    } catch (err: any) {
        console.log(err);
    }
});

export const saveNotification = createAsyncThunk('post/save', async (payload: any) => {
    try {
        const response = await axiosInstance_ORCHAPI.post(`/acknowledgement/save`, payload);
        return response;
    } catch (err: any) {
        console.log(err);
    }
});

export const findPrescriber = createAsyncThunk('post/findPrescriber', async (userId: string) => {
    const userPayload = {
        prescriberId: userId,
    };
    const response = await axiosInstancePrescriber.post(`/v1/prescriber/findprescriber`, userPayload);
    console.log(response);

    return response;
});

export const findPrescriberPINisValid = createAsyncThunk('post/findPrescriberPINisValid', async (userId: string) => {
    const userPayload = {
        prescriberExtUniqueId: userId,
    };
    const response = await axiosInstancePrescriber.post(`/v1/prescriber/findprescriber`, userPayload);
    console.log(response);

    return response;
});

export const validatePatient = createAsyncThunk('post/validatePatient', async (payload: any) => {
    const response = await axiosInstance_2.post(`/validate`, payload);
    console.log(response);

    return response['payload'];
});

// export const patient_Search = createAsyncThunk('get/patientSearch', async () => {

//     const response = await axiosInstance_2.get(`/findPatientByLastName?lastName=Amass&patientExternalUniqueId=724649606&dob=01-MAR-1971&product=Thalomid`)

//     // return response['payload'];
// });
// export const patient_search_reactivation = createAsyncThunk(
//     'post/patientsearchreactivation',
//     async (params: { patientExternalUniqueId: string; product: string }) => {
//         console.log('Patient Enrollment Request', params);
//         const response = await axiosInstance_PatientReactivation.post(`/validate-reactivation`, params);
//         return response;
//     }
// );

// export const patient_search_reactivation = createAsyncThunk('post/patientsearchreactivation', async(params :{
//     patientExternalUniqueId : string,
//     product : string,

// }) => {
//     try {
//         const response = await axiosInstance_PatientReactivation.post('/validate-reactivation', params);
//         return response;
//     } catch (err: any) {
//         console.log(err)
//         return err.response.data.data.errorMesssage.messageKey;
//     }
// });

export const patient_Search_PartialPPAF = createAsyncThunk(
    'post/patientsearchpartialPPAF',
    async (payload: {
        patientLastName: string;
        patientExternalUniqueId: string;
        patientDob: string;
        patientProduct: string;
    }) => {
        try {
            // let navigate = useNavigate();
            const response = await axiosInstance_Patient_PartialPPAF.post(
                '/get-patient-from-camunda-instance',
                payload
            );
            // dispatch(patientCamundaStore(response));
            return response;
        } catch (err: any) {
            return err.response.data.data.errorMesssage.messageKey;
        }
    }
);
export const fetchDropdown = createAsyncThunk('post/fetchDropdown', async (keys: any) => {
    const reqBody = {
        keys: keys,
    };
    try {
        const response = await axiosInstance.post(`/referencedata`, reqBody);
        return response;
    } catch (err) {
        console.error(`error fetching dropdown ${err}`);
    }
});

export const fetchAuthStatusForPrescriberCalender = createAsyncThunk(
    'post/fetchAuthStatusForPrescriberCalender',
    async (keys: any) => {
        const reqBody = {
            keys: keys,
        };
        try {
            const response = await axiosInstance.post(`/referencedata`, reqBody);
            return response;
        } catch (err) {
            console.error(`error fetching auth status ${err}`);
        }
    }
);

export const getActivePharmacy = createAsyncThunk('post/getActivePharmacy', async (onlineUserName: any) => {
    try {
        const response = await axiosInstancePharmacy.post('/v1/pharmacy/getActivePharmacy', {
            onlineUserName: onlineUserName,
        });
        return response;
    } catch (err) {
        console.error(`error fetching dropdown ${err}`);
    }
});
export const getBasePharmacy = createAsyncThunk('post/getBasePharmacy', async (onlineUserName: any) => {
    try {
        const response = await axiosInstancePharmacy.post('/v1/pharmacy/getBasePharmacy', {
            onlineUserName: onlineUserName,
        });
        return response;
    } catch (err) {
        console.error(`error fetching dropdown ${err}`);
    }
});
//* Pharmacy Account Validation *//
export const checkPharAccountDetailsValidation = createAsyncThunk(
    'post/CheckPharValidation',
    async (accDetails: any) => {
        const { values } = accDetails;
        const Validatepharmacy = {
            accessCode: values.accessCode,
            ncpdpNumber: values.NCPDPNumber,
            deaNumber: values.DEANumber.toUpperCase(),
        };
        const { userName, ...userObj } = values;
        // try {
        //     const response: any = await axiosInstanceNewUrl.post(`pharmacy-api/v1/pharmacy/validate-pharmacy`, Validatepharmacy);
        //    if (response.data?.message === 'Success') {
        try {
            // const response = await axiosInstanceNewUrl.post(`authentication-api/v1/validateUsername`, {
            const response = await axiosInstanceCognitoUrl.post(`/ValidateUsername`, {
                username: userName,
                group_tag: RoleByGroup.Pharmacist,
            });
            return response.data;
        } catch (err: any) {
            return err.response.data.message;
        }
        //     } else {
        //         return response.response.data.data.errorMesssage.messageKey;
        //     }
        // } catch (err: any) {
        //     return err.response.data.data.errorMesssage.messageKey;
        // }
    }
);

export const fetchValidateAuthorization = createAsyncThunk('get/fetchValidateAuthorization', async (payload: any) => {
    const response = await axiosInstanceSurvey.post(`/v1/dispensation/validate-authorization`, payload.data);
    return response;
});
export const searchAuthorization = createAsyncThunk('/get/authorization', async (payload: any) => {
    const { authorizationNumber, prescriberId, nonPrescriberid, isPrescriber } = payload;
    const paramsBasedOnPrescriberNonPrescriberId = prescriberId
        ? `prescriberId=${prescriberId}`
        : `nonPrescriberid=${nonPrescriberid}`;
    const response = await axiosInstanceSurvey.get(
        `/v1/authorization/get-authorization-by-prescriberid-nonprescriberid?${paramsBasedOnPrescriberNonPrescriberId}&isPrescriber=${isPrescriber}&authorizationNumber=${authorizationNumber}`
    );
    return response;
});

export const pharmacyReviewAuth = createAsyncThunk('/get/getPharmacyReviewAuth', async (payload: any) => {
    const { authorizationNumber } = payload;
    const response = await axiosInstanceSurvey.get(
        `/v1/dispensation/get-authorization-for-patient?authorizationNumber=${authorizationNumber}`
    );
    return response;
});

export const getActivePharmacyLocation = createAsyncThunk('get/getActivePharmacyLocation', async (payload: any) => {
    const response = await axiosInstancePharmacy.post(`/v1/pharmacy/getActivePharmacyLocation`, payload);
    return response;
});

export const getPharmacyLocation = createAsyncThunk('get/getPharmacyLocation', async (payload: any) => {
    const response = await axiosInstancePharmacy.post(`/v1/pharmacy/find-pharmacy`, payload);
    return response;
});

export const updatePharmacyLocation = createAsyncThunk('put/updatePharmacyLocation', async (payload: any) => {
    const response = await axiosInstancePharmacy.post(`/v1/pharmacy/updatePharmacyLocation`, payload);
    return response;
});

export const createLogDispense = createAsyncThunk('post/createDispense', async (payload: any) => {
    const response = await axiosInstanceSurvey.post(`/v1/dispensation/create`, payload);
    return response;
});

export const getPharmacyByPharmacyId = createAsyncThunk('post/pharmacy', async (payload: any) => {
    const response = await axiosInstancePharmacy.post(`/v1/pharmacy/find-pharmacy`, {
        pharmacyId: payload.id,
        channelId: payload.channelID,
    });
    return response;
});
export const prescriberPatientsSearch = createAsyncThunk('post/prescriberPatientsSearch', async (payload: any) => {
    const response = await axiosInstanceSearch.post(
        `/prescriberPatientsSearch?page=${payload.pageNumber ? payload.pageNumber : 0}`,
        {
            prescriberNonPrescriberId: payload.prescriberNonPrescriberId,
            patientNameLast: payload.patientNameLast ? payload.patientNameLast : null,
            assocPrescriberNameLast: payload.assocPrescriberNameLast ? payload.assocPrescriberNameLast : null,
            assocPrescriberId: payload.assocPrescriberId ? payload.assocPrescriberId : null,
            sortByField: payload.sortByField ? payload.sortByField : null,
            sortByOrder: payload.sortByOrder ? payload.sortByOrder : null,
            authorizationNumber: payload.authorizationNumber ? payload.authorizationNumber : null,
            patientExtUniqueId: payload.patientExtUniqueId ? payload.patientExtUniqueId : null,
            isPrescriber: payload.isPrescriber,
        }
    );
    return response;
});
export const getConfirmedDispensation = createAsyncThunk('post/getConfirmedDispensation', async (payload: any) => {
    const response = await axiosInstanceSurvey.post(
        `/v1/dispensation/get-confirmed-dispensations?page=${
            payload?.currentPage ? payload.currentPage : 0
        }&showAll=${false}&pageSize=${5}`,
        {
            patientId: payload?.patientId || null,
            groupByDispenseSequence: false,
        }
    );
    return response;
});
export const getAdditionalPatientData = createAsyncThunk(
    'additionalPatientDetails',
    async (payload: { authorizationNumberList: [] }) => {
        const response = await axiosInstanceSurvey.post(`/v1/surveys/find-survey-data-by-authorization`, payload);
        return response;
    }
);

export const getPrescription = createAsyncThunk('getPrescription', async (payload: any) => {
    const response = await axiosInstanceSurvey.get(
        `/v1/prescription?patientId=${payload.data.patientId}&prescriberId=${payload.data.prescriberId}&productCountryId=${payload.data.productCountryId}`
    );
    return response;
});
export const authorizationSearch = createAsyncThunk('authorizationSearch', async (payload: any) => {
    const response = await axiosInstanceSearch.post(`/authorizationSearch`, payload.data);
    return response;
});

export const getPrescAssociation = createAsyncThunk('getPrescAssociation', async (payload: any) => {
    const response = await axiosInstancePrescriber.get(
        `/v1/prescriber/prescriber-associations?prescriberId=${payload.data.prescriberId}`
    );
    return response;
});

export const getNonPrescAssociation = createAsyncThunk('getNonPrescAssociation', async (payload: any) => {
    const response = await axiosInstancePrescriber.get(
        `/v1/prescriber/non-prescriber-associations?onlineUserId=${payload.data.onlineUserId}`
    );
    return response;
});

export const removeAssociation = createAsyncThunk('removeAssociation', async (payload: any) => {
    const response = await axiosInstancePrescriber.post(`/v1/prescriber/remove-associations`, payload.data);
    return response;
});

export const prescriberCalendarInfo = createAsyncThunk('prescriberCalendarInfo', async (payload: any) => {
    const response = await axiosInstanceSearch.post(`/get-prescriber-calendar-info`, payload.data);
    return response;
});

export const runReport = createAsyncThunk('runReport', async (payload: any) => {
    const response = await axiosInstanceSearch.post(`/authorization-expiration-report`, payload.data);
    return response;
});

export const histAuthReportAPI = createAsyncThunk('historicAuthReport', async (payload: any) => {
    const response = await axiosInstanceSearch.post(`/report/historical-authorization`, payload.data);
    return response;
});

export const hiddenReportAPI = createAsyncThunk('hiddenReport', async (payload: any) => {
    const response = await axiosInstanceSearch.post(`/report/hidden-patient`, payload.data);
    return response;
});

export const setAccessCodeAPI = createAsyncThunk(
    'setAccessCodeAPI',
    async (payload: { systemId: string; onlineUserId: string; token: string }) => {
        const response = await axiosInstancePrescriber.post(
            `v1/prescriber/update-prescriber-onlineid`,
            { prescriberSystemId: +payload.systemId, onlineUserId: payload.onlineUserId },
            {
                headers: { Authorization: `Bearer ${payload.token}` },
            }
        );
        return response;
    }
);

export const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        changeProgram: (state, action) => {
            state.selectedProgram = action.payload;
        },
        setPPAFContent: (state, action) => {
            state.ppafContent = action.payload;
        },
        resetProgram: (state) => {
            state.selectedProgram = {
                label: '',
                id: '',
                image: '',
                prescribing_info: '',
            };
        },

        selectedPatientProgram: (state, action) => {
            state.selectedPatientProgram = action.payload;
        },
        patientProgramList: (state, action) => {
            state.patientProgramList = action.payload;
        },
        patientChecklist: (state, action) => {
            state.patientChecklist = action.payload;
        },
        setLoginPage: (state, action) => {
            state.loginPage = action.payload;
        },
        PharmacyStore: (state, action) => {
            console.log('pharmacystore', action.payload);
            state.PharmacyStore = action.payload;
        },
        patientInformation: (state, action) => {
            state.patientInformation = action.payload;
        },
        patientSearchStore: (state, action) => {
            console.log('%cappSlice.ts line:437 action.payload', 'color: #007acc;', action.payload);
            state.patientSearchStore = action.payload;
        },
        patientPrescriberStore: (state, action) => {
            console.log('%cappSlice.ts line:437 action.payload', 'color: #007acc;', action.payload);
            state.patientPrescriberStore = action.payload;
        },

        patientCamundaStore: (state, action) => {
            console.log('%cappSlice.ts line:501 action.payload', 'color: #007acc;', action.payload);
            state.patientCamundaStore = action.payload;
        },
        patientReactivationStore: (state, action) => {
            console.log('%cappSlice.ts line:437 action.payload', 'color: #007acc;', action.payload);
            state.patientReactivationStore = action.payload;
        },
        patientReactivationSpecialStore: (state, action) => {
            console.log('%cappSlice.ts line:437 action.payload', 'color: #007acc;', action.payload);
            state.patientReactivationSpecialStore = action.payload;
        },
        activePharmacy: (state, action) => {
            console.log('%cappSlice.ts line:437 action.payload', 'color: #007acc;', action.payload);
            state.activePharmacy = action.payload;
        },

        setPatientData: (state, action) => {
            state.patientResponse = action.payload;
        },
        setPatientId: (state, action) => {
            state.patientId = action.payload;
        },
        setPatientRisk: (state, action) => {
            state.existingPatientRisk = action.payload;
        },
        handlePrescriber: (state, action) => {
            state.role = action.payload;
        },
        changeHelpPage: (state, action) => {
            state.helpURL = action.payload;
        },
        setAccFormDetails: (state, action) => {
            state.accFormDetails = action.payload;
        },
        setAccountPwdDetails: (state, action) => {
            state.passwordField = action.payload;
        },
        setProductEnrolmentData: (state, action) => {
            state.productEnrolmentData = action.payload;
        },
        showPV0030Msg: (state, action) => {
            state.showPV0030Msg = action.payload;
        },
        setActiveEnrollments: (state, action) => {
            state.activeEnrollments = action.payload;
        },
        setPartialPatientPPafFlow: (state, action) => {
            state.patientPPafFlow = action.payload;
        },
        setPharmacyAuthenticate: (state, action) => {
            state.pharmacyAuthenticate = action.payload;
        },
        setAssociationData: (state, action) => {
            state.associationData = action.payload;
        },

        setPrescriberEnrollment: (state, action) => {
            state.prescriberEnrollment = action.payload;
        },
        resetApp: (state) => {
            sessionStorage.removeItem('pass');
            sessionStorage.removeItem('accountData');
            sessionStorage.removeItem('productaccDetails');
            sessionStorage.removeItem('productSecDetails');
            return { ...initialState, role: state.role };
        },
        resetUserAcc: (state) => {
            state.userAccount = {
                message: '',
            };
            state.errorCreateAccount = {
                message: '',
            };
        },
        resetAccFormDetails: (state) => {
            state.accFormDetails = {
                firstName: '',
                lastName: '',
                officeAddress: '',
                city: '',
                state: '',
                zipCode: '',
                phoneNumber: '',
                userName: state.accFormDetails.userName,
                email: '',
                securityQuestion: '',
                securityAnswer: '',
            };
        },
        resetAccValidation: (state) => {
            state.errorValidation = { message: '', errorMsg: '' };
        },
        resetPatientInformation: (state) => {
            state.patientInformation = {};
        },
        changeHelpContent: (state, action) => {
            state.isSubmitAndPrint = action.payload;
        },
        changeHelpContentForAccount: (state, action) => {
            state.isAccount = action.payload ? 'createaccountsuccess' : 'createaccountconfirmation';
        },
        changePPAF: (state, action) => {
            state.isPPAF = action.payload;
        },
        changePrescriberDetails: (state, action) => {
            state.prescriberDetails = action.payload;
        },
        changeHelpContentKey: (state, action) => {
            state.upatedHelpContentKey = action.payload;
        },
        resetpatientPrescriberStore: (state) => {
            state.patientPrescriberStore = {};
        },
        prescriberSurveySearchStore: (state, action) => {
            console.log('line 666', action.payload);
            state.prescriberSurveySearchStore = action.payload;
        },
        resetPrescriberDetails: (state) => {
            state.prescriberDetails = {
                firstName: '',
                lastName: '',
                pin: '',
                prescriberSystemId: '',
                mi: '',
                addressList: [''],
                selectedAddress: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                ext: '',
                fax: '',
                addressId: '',
                email: '',
                officeName: '',
                attention: '',
                onlineUserid: '',
            };
        },
        updateSearchPatient: (state, action) => {
            state.searchPatientDto = action.payload.data.data;
        },
        updatePatientSurvey: (state, action) => {
            state.patientSurvey = action.payload;
        },
        resetPatientSurvey: (state) => {
            state.patientSurvey = initialState.patientSurvey;
        },
        setLogMultipleDispense: (state, action) => {
            state.logMultipleDispense = action.payload;
        },
        resetLogMultipleDispense: (state) => {
            state.logMultipleDispense = initialState.logMultipleDispense;
            state.authorizationData = initialState.authorizationData;
        },
        setPrescriptionFormData: (state, action) => {
            state.prescriptionFormData = action.payload;
        },
        setPrescriptionFormDetailsData: (state, action) => {
            state.prescriptionFormDetailsData = action.payload.data;
        },
        deletePrescriptionFormDetailsData: (state, action) => {
            state.prescriptionFormDetailsData = {};
        },
        setPrescriptionDosage: (state, action) => {
            state.prescriptionFormDosage = action.payload.data;
        },
        deletePrescriptionDosage: (state, action) => {
            state.prescriptionFormDosage = {};
        },
        deletePrescriptionAuthDetails: (state, action) => {
            state.prescriptionAuthDetails = {};
        },
        storePrescriptionData: (state, action) => {
            state.prescriptionSaveApi = action.payload;
        },
        storeActionType: (state, action) => {
            state.actionTypes = action.payload;
        },
        storeCalenderActionitemInfo: (state, action) => {
            state.patientDataFromCalender = action.payload;
        },
        clearPharmacyLocation: (state) => {
            state.pharmacyLocation = {};
        },
        updateSelectedPatient: (state, action) => {
            state.selectedPatient = action.payload;
        },
        clearSelectedPatient: (state) => {
            state.selectedPatient = null;
        },
        setPrescriptionPaylod: (state, action) => {
            state.prescriptionPaylod = action.payload;
        },
        setViewCalendarData: (state, action) => {
            state.calendarData = action.payload.data;
        },
        deleteCalendarData: (state, action) => {
            state.calendarData = [];
        },
        setActionItemInfo: (state, action) => {
            state.actionitemInfo = action.payload;
        },
        setPrescriberDashboardCalenderDynamicData: (state, action) => {
            state.prescriberDashboardCalenderDynamicData = action.payload;
        },
        setPrescriberCalendarActionItemPayload: (state, action) => {
            state.calendarActionItemPayload = action.payload;
        },
        setSurveyDataFromPatientDetails: (state, action) => {
            state.surveyDataFromPatientDetails = action.payload;
        },
        setSurveyInfoFromRoute: (state, action) => {
            state.surveyInfoFromRoute = action.payload;
        },
        resetDispenseHistory: (state) => {
            state.dispenseHistoryList = {}
        },
        setIsWritePrescription:(state,action)=>{
            state.isWritePrescription=true;
        },
        deleteIsWritePrescription:(state,action)=>{
            state.isWritePrescription=false;
        },
        setLoginHomePrescriber:(state,action)=>{
            state.loginHomePrescriber=action.payload
        },
        setLoginHomePharmacy:(state,action)=>{
            state.loginHomePharmacy=action.payload
        },
        resetAppInfo: (state) => {
            sessionStorage.removeItem('pass');
            sessionStorage.removeItem('accountData');
            sessionStorage.removeItem('productaccDetails');
            sessionStorage.removeItem('productSecDetails');
            return { ...initialState, role: state.role, loginHomePrescriber: state.loginHomePrescriber, loginHomePharmacy: state.loginHomePharmacy };
        },
        setPatientIntent:(state,action)=>{
            state.patientIntent=action.payload
        },
        resetPatientEnrollmentInfo:(state)=>{
            state.patientCamundaStore= initialState.patientCamundaStore;
            state.patientResponse= initialState.patientResponse;
            state.patientReactivationStore= initialState.patientReactivationStore;
            state.patientReactivationSpecialStore= initialState.patientReactivationSpecialStore;
            state.ppafContent= initialState.ppafContent;
            state.patientProgramList= initialState.patientProgramList;
            state.patientSignedPPAF=false;
        },
        setPatientSignedPPAF:(state,action)=>{
            state.patientSignedPPAF= action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(prescriberHomeThunk.fulfilled, (state, action) => {
            state.prescriberHome = action.payload;
        });
        builder.addCase(prescriberHomeThunk.rejected, (state, action) => {
            state.errorPrescriberHome = { message: 'failed to load' };
        });
        builder.addCase(createUserAccount.pending, (state, action) => {
            state.errorCreateAccount = { message: '', errorMsg: '' };
        });
        builder.addCase(searchAuthorization.fulfilled, (state, action) => {
            if (action.payload?.data?.statusCode === 200) {
                state.authorizationData = action.payload?.data?.data;
            } else {
                state.authorizationData = [];
            }
        });
        builder.addCase(searchAuthorization.rejected, (state, action) => {
            state.authorizationData = [];
        });
        builder.addCase(pharmacyReviewAuth.fulfilled, (state, action) => {
            if (action.payload?.data?.statusCode === 200) {
                state.authorizationData = action.payload?.data?.data;
            } else {
                state.authorizationData = [];
            }
        });
        builder.addCase(pharmacyReviewAuth.rejected, (state, action) => {
            state.authorizationData = [];
        });
        builder.addCase(getPharmacyLocation.fulfilled, (state, action) => {
            if (action.payload?.data?.statusCode === 200) {
                state.pharmacyLocation = action.payload?.data?.data;
            } else {
                state.pharmacyLocation = {};
            }
        });
        builder.addCase(getPharmacyLocation.rejected, (state) => {
            state.pharmacyLocation = {};
        });
        builder.addCase(getActivePharmacyLocation.fulfilled, (state, action) => {
            if (action.payload?.data?.statusCode === 200) {
                state.activePharmacyLocation = action.payload?.data?.data;
            } else {
                state.activePharmacyLocation = {};
            }
        });
        builder.addCase(getActivePharmacyLocation.rejected, (state) => {
            state.activePharmacyLocation = {};
        });
        builder.addCase(prescriberPatientsSearch.pending, (state) => {
            state.prescriberDashboardFindPatientIsLoading = true;
        });
        builder.addCase(prescriberPatientsSearch.fulfilled, (state, action) => {
            state.prescriberDashboardFindPatientIsLoading = false;
            if (action.payload?.data?.statusCode === 200) {
                state.patientList = action.payload?.data?.data;
            } else {
                state.patientList = {};
            }
        });
        builder.addCase(prescriberPatientsSearch.rejected, (state) => {
            state.patientList = {};
        });
        builder.addCase(getConfirmedDispensation.fulfilled, (state, action) => {
            if (action.payload?.data?.statusCode === 200) {
                state.dispenseHistoryList = action.payload?.data?.data;
            } else {
                state.dispenseHistoryList = {};
            }
        });
        builder.addCase(getConfirmedDispensation.rejected, (state) => {
            state.dispenseHistoryList = {};
        });
        builder.addCase(createUserAccount.fulfilled, (state, action: any) => {
            if (action?.payload?.response?.UserConfirmed) {
                state.errorCreateAccount = { message: 'Success', errorMsg: '' };
                if (action?.payload?.authResult?.AuthenticationResult?.AccessToken) {
                    const authRes = congitoResMapper(action?.payload?.authResult?.AuthenticationResult || {});
                    sessionStorage.setItem('token', action?.payload?.authResult?.AuthenticationResult?.AccessToken);
                    state.userInfo = authRes?.currentUser?.userDets;
                    indexedDBStore.setItem('prescriberUser', { currentUser: authRes?.currentUser });
                }
            } else {
                state.errorCreateAccount = { message: action?.payload?.response?.message, errorMsg: '' };
            }
        });
        builder.addCase(createUserAccount.rejected, (state, action: any) => {
            state.errorCreateAccount = { message: action?.payload?.message };
        });
        builder.addCase(createUserAccountPharmacy.fulfilled, (state, action: any) => {
            if (action?.payload?.response?.UserConfirmed) {
                state.errorCreateAccount = { message: 'Success', errorMsg: '' };
                if (action?.payload?.authResult?.AuthenticationResult?.AccessToken) {
                    const authRes = congitoResMapper(action?.payload?.authResult?.AuthenticationResult || {});
                    sessionStorage.setItem('token', action?.payload?.authResult?.AuthenticationResult?.AccessToken);
                    sessionStorage.setItem('role', authRes?.currentUser?.groupTag);
                    indexedDBStore.setItem('pharmacyUser', { currentUser: authRes?.currentUser });
                    state.userInfo = authRes?.currentUser?.userDets;
                }
            } else {
                state.errorCreateAccountPharmacy = { message: action?.payload?.response?.message, errorMsg: '' };
            }
        });
        builder.addCase(createUserAccountPharmacy.rejected, (state, action: any) => {
            state.errorCreateAccountPharmacy = { message: action?.payload?.message };
        });
        builder.addCase(checkAccountDetailsValidation.pending, (state, action) => {
            state.errorValidation = { message: '', errorMsg: '' };
        });
        builder.addCase(checkAccountDetailsValidation.fulfilled, (state, action) => {
            if (action.payload.statusCode === 200) {
                state.errorValidation = {
                    message: 'Validation Error',
                    errorMsg:
                        'The user name you have chosen is taken. Please enter a different user name.' /*  action.payload.message */,
                };
            } else if (action.payload.statusCode === 502) {
                state.errorValidation = { message: 'Success', errorMsg: null };
            } else {
                state.errorValidation = { message: 'Validation Error', errorMsg: action.payload };
            }
        });
        builder.addCase(checkAccountDetailsValidation.rejected, (state, action) => {
            state.errorValidation = { message: 'Validation Error', errorMsg: '' };
        });

        builder.addCase(fetchCurrentPrescriber.pending, (state, action) => {
            state.errorFindPrescriber = { message: '' };
            // state.prescriberEnrollment = ['']; //For NonPrescriber
            //state.prescriberUniqueID = '';
        });
        builder.addCase(fetchCurrentPrescriber.fulfilled, (state, action) => {
            //const location = action.payload.data.data.prescriberLocations.find((item) => item.primaryLocation === 'Y');
            if (action.payload.status === 200) {
                state.prescAllDetails = action.payload.data.data;
                console.log(action.payload.data.data);
                if (action.payload.data.data.prescriberRegStatuses.length >= 0) {
                    const enrollmentPayload = action.payload.data.data.prescriberRegStatuses;
                    state.prescriberUniqueID = action.payload.data.data.prescriberExtUniqueId1;
                    let arrayEnrolledProgram: any = [];
                    enrollmentPayload.forEach((item) => {
                        let EnrolledProgram = {
                            productName: item.id.productCountryId.product.productName,
                            productCountryId: item.id.productCountryId.productCountryId,
                            status: item.registrationStatus.registrationStatus,
                        };
                        arrayEnrolledProgram.push(EnrolledProgram);
                    });
                    state.prescriberEnrollment = [...arrayEnrolledProgram];
                }
                const products = action.payload.data.data?.prescriberRegForms?.map(
                    (item) => item?.id?.productCountryId?.product?.productId?.toString()
                );

                state.enrolledProducts = [...products];

                //update Selected prescriber details
                state.prescriberDetails.firstName = action.payload.data.data.prescriberNameFirst;
                state.prescriberDetails.lastName = action.payload.data.data.prescriberNameLast;
                state.prescriberDetails.mi =
                    action.payload.data.data.prescriberMiddleInitial === null
                        ? ''
                        : action.payload.data.data.prescriberMiddleInitial;
                state.productEnrolmentData.mi = action.payload.data.data.prescriberMiddleInitial;
                state.prescriberDetails.pin = action.payload.data.data.prescriberExtUniqueId1;
                state.prescriberDetails.meNumber = action.payload.data.data.prescriberExtUniqueId2;
                state.prescriberDetails.prescriberSystemId = action.payload.data.data.prescriberId;
                state.prescriberDetails.onlineUserid = action.payload.data.data.onlineUserid;

                if (action.payload.data.data.prescriberLocations.length >= 0) {
                    const locations = action.payload.data.data.prescriberLocations;
                    let arrayaddrList: any = [];
                    locations.forEach((item) => {
                        if (item.prescriberLocationDeactDate === null) {
                            let phoneNumber = item.phoneNumber;
                            let extVal = '';
                            if (phoneNumber.indexOf('x') >= 0) {
                                phoneNumber = phoneNumber.split('x')[0];
                                extVal = item.phoneNumber.split('x')[1];
                            }
                            let addressList = {
                                primaryLocation: item.primaryLocation,
                                address: item.addressLine1,
                                city: item.postalCode.city,
                                state: item.postalCode.state.stateCode,
                                zip: item.postalCode.postalCode1,
                                phone: phoneNumber,
                                fax: item.faxNumber,
                                ext: extVal,
                                prescriberLocationSequence: item.id.prescriberLocationSequence.toString(),
                            };
                            if (item.primaryLocation === 'Y') {
                                state.prescriberDetails.selectedAddress = item.addressLine1;
                                state.prescriberDetails.city = item.postalCode.city;
                                state.prescriberDetails.state = item.postalCode.state.stateCode;
                                state.prescriberDetails.zip = item.postalCode.postalCode1;
                                state.prescriberDetails.phone = phoneNumber;
                                state.prescriberDetails.ext = extVal;
                                state.prescriberDetails.fax = item.faxNumber;
                                state.prescriberDetails.officeName = item.prescriberOfficeName;
                                state.prescriberDetails.attention = item.attention;
                                state.prescriberDetails.email = item.emailAddress;
                                state.prescriberDetails.addressId = item.id.prescriberLocationSequence.toString();
                                state.productEnrolmentData.officeName = item.prescriberOfficeName;
                                state.productEnrolmentData.attention = item.attention;
                                state.productEnrolmentData.phone = getFormattedPhone(item.phoneNumber);
                                state.productEnrolmentData.fax = item.faxNumber;
                                state.prescriberDetails.prescriberSystemId = action.payload.data.data.prescriberId;
                                state.prescriberDetails.onlineUserid = action.payload.data.data.onlineUserid;
                            }
                            arrayaddrList.push(addressList);
                            // if(item.primaryLocation !== "Y")
                            // {

                            //     state.productEnrolmentData.officeName2=item.prescriberOfficeName;
                            //     state.productEnrolmentData.attention2=item.attention;
                            //     state.productEnrolmentData.address2=item.addressLine1;
                            //     state.productEnrolmentData.city2=item.postalCode?.city;

                            //     state.productEnrolmentData.state2=item.postalCode?.state?.stateCode;
                            //     state.productEnrolmentData.zip2=item.postalCode?.postalCode1;
                            //     state.productEnrolmentData.phone2=getFormattedPhone(item.phoneNumber);
                            //     state.productEnrolmentData.fax2=item.faxNumber;
                            //     state.productEnrolmentData.email2=item.emailAddress;
                            // }
                        }
                    });
                    state.prescriberDetails.addressList = [...arrayaddrList];
                }

                const minDegree = Math.min(...action.payload.data.data.degrees?.map((item) => item.prescriberDegreeId));
                const degrees =
                    minDegree &&
                    action.payload.data.data.degrees?.filter((item) => {
                        if (item.prescriberDegreeId === minDegree) {
                            return item;
                        }
                    });
                console.log('degrees', action.payload.data.data.degrees, degrees);
                state.productEnrolmentData.degree = degrees[0]?.degree;
                state.productEnrolmentData.specialty = `${action.payload.data.data.specialty.specialtyName} - ${action.payload.data.data.specialty.description}`;
            } else {
                state.errorFindPrescriber = { message: 'failed to load' };
                // state.prescriberEnrollment = ['']; for nonPrescriber
                state.prescriberUniqueID = '';
            }
        });
        builder.addCase(fetchCurrentPrescriber.rejected, (state, action) => {
            state.errorFindPrescriber = { message: 'failed to load' };
            state.prescriberEnrollment = [''];
            state.prescriberUniqueID = '';
        });
        builder.addCase(findPrescriber.pending, (state, action) => {
            state.errorFindPrescriber = { message: '' };
            state.prescriberEnrollment = [''];
            state.prescriberUniqueID = '';
        });
        builder.addCase(findPrescriber.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data.data.prescriberRegStatuses.length >= 0) {
                    const enrollmentPayload = action.payload.data.data.prescriberRegStatuses;
                    state.prescriberUniqueID = action.payload.data.data.prescriberExtUniqueId1;
                    let arrayEnrolledProgram: any = [];
                    enrollmentPayload.forEach((item) => {
                        let EnrolledProgram = {
                            productName: item.id.productCountryId.product.productName,
                            productCountryId: item.id.productCountryId.productCountryId,
                            status: item.registrationStatus.registrationStatus,
                        };
                        arrayEnrolledProgram.push(EnrolledProgram);
                    });
                    state.prescriberEnrollment = [...arrayEnrolledProgram];
                }
            } else {
                state.errorFindPrescriber = { message: 'failed to load' };
                state.prescriberEnrollment = [''];
                state.prescriberUniqueID = '';
            }
        });
        builder.addCase(popUpNotification.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                const filteredPopups: any[] = [];
                action.payload.data.data.forEach((popup) => {
                    if (popup.productCode === 'General' || state.activeEnrollments.includes(popup.productCode)) {
                        filteredPopups.push(popup);
                    }
                });
                state.popUpData = filteredPopups;
            }
        });
        builder.addCase(popUpNotification.rejected, (state, action) => {
            state.popUpData = [];
        });
        builder.addCase(popUpNotification.pending, (state, action) => {
            state.popUpData = [];
        });
        builder.addCase(findPrescriber.rejected, (state, action) => {
            state.errorFindPrescriber = { message: 'failed to load' };
            state.prescriberEnrollment = [''];
            state.prescriberUniqueID = '';
        });
        builder.addCase(findPrescriberPINisValid.pending, (state, action) => {
            state.errorPINValidCheck = { message: '' };
            state.prescriberEnrollment = [''];
        });
        builder.addCase(findPrescriberPINisValid.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.isPrescriberIdNumValid = true;
                if (action.payload.data.data.prescriberRegStatuses.length >= 0) {
                    const enrollmentPayload = action.payload.data.data.prescriberRegStatuses;

                    let arrayEnrolledProgram: any = [];
                    enrollmentPayload.forEach((item) => {
                        let EnrolledProgram = {
                            productName: item.id.productCountryId.product.productName,
                            productCountryId: item.id.productCountryId.productCountryId,
                            status: item.registrationStatus.registrationStatus,
                        };
                        arrayEnrolledProgram.push(EnrolledProgram);
                    });
                    state.prescriberEnrollment = [...arrayEnrolledProgram];
                }
                state.errorPINValidCheck = { message: '' };
            } else if (action.payload.status === 204) {
                state.isPrescriberIdNumValid = false;
                state.errorPINValidCheck = { message: '' };
                state.prescriberEnrollment = [''];
            } else {
                state.errorPINValidCheck = { message: 'failed to load' };
                state.prescriberEnrollment = [''];
            }
        });
        builder.addCase(findPrescriberPINisValid.rejected, (state, action) => {
            state.errorPINValidCheck = { message: 'failed to load' };
        });
        builder.addCase(updatePassword.fulfilled, (state, action) => {});
        builder.addCase(updatePassword.rejected, (state, action) => {});
        builder.addCase(resetPasswordDR.fulfilled, (state, action) => {});
        builder.addCase(resetPasswordDR.rejected, (state, action) => {});
        builder.addCase(forgotPasswordDR.fulfilled, (state, action) => {});
        builder.addCase(forgotPasswordDR.rejected, (state, action) => {});
        builder.addCase(resetPasswordManage.fulfilled, (state, action) => {});
        builder.addCase(resetPasswordManage.rejected, (state, action) => {});
        builder.addCase(resetPasswordManagePhar.fulfilled, (state, action) => {});
        builder.addCase(resetPasswordManagePhar.rejected, (state, action) => {});
        builder.addCase(validateAndCreateOneTimePwd.fulfilled, (state, action) => {});
        builder.addCase(validateAndCreateOneTimePwd.rejected, (state, action) => {});
        builder.addCase(validatePatient.fulfilled, (state, action) => {
            state.patientPINStatus = action.payload.status;
            if (action.payload.status === '500') {
                state.errorValidatePatient = { message: 'Concurrent Failure' };
            }
        });
        builder.addCase(validatePatient.rejected, (state, action) => {
            state.errorValidatePatient = { message: 'Failed to load' };
        });
        // builder.addCase(patient_search_reactivation.fulfilled, (state, action) => {
        //     if (action.payload.status === '500') {
        //         console.log('internal server error');
        //     }
        // });
        // builder.addCase(patient_search_reactivation.rejected, (state, action) => {
        //     console.log('Patient Reactivation failed', action);
        // });

        builder.addCase(patient_Search_PartialPPAF.fulfilled, (state, action) => {
            // const dispatch = useAppDispatch();
            // state.camundaInstance=action.payload.data.data;
            // const navigate = useNavigate();
            if (action?.payload?.data?.statusCode === 200) {
                state.camundaInstance = action.payload.data;
                // dispatch(patientCamundaStore(action.payload.data.data))
                // navigate('/prescriber/prescriberpatientsearch')

                // dispatch(patientCamundaStore(action?.payload?.data?))
                //     const URLNAV =()=>{
                //         const navigate = useNavigate();
                //     navigate('/prescriber/prescriberpatientsearch');
                // }
            }
            // if (action?.payload?.statusCode === 200) {
            //     state.data=action.payload.data.data;
            //     // navigate('/prescriber/prescriberpatientsearch')
            // }
        });
        // builder.addCase(checkPharAccountDetailsValidation.pending, (state, action) => {
        //     state.errorValidation = { message: '', errorMsg: '' };
        // });
        // builder.addCase(checkPharAccountDetailsValidation.fulfilled, (state, action) => {
        //     if (action.payload.statusCode === 200) {
        //         state.errorValidation = {
        //             message: 'Validation Error',
        //             errorMsg:
        //                 'The user name you have chosen is taken. Please enter a different user name.' /*  action.payload.message */,
        //         };
        //     } else if (action.payload.statusCode === 502) {
        //         state.errorValidation = { message: 'Success', errorMsg: null };
        //     } else {
        //         state.errorValidation = { message: 'Validation Error', errorMsg: action.payload };
        //     }
        // });
        // builder.addCase(checkPharAccountDetailsValidation.rejected, (state, action) => {
        //     state.errorValidation = { message: 'Validation Error', errorMsg: '' };
        // });

        builder.addCase(patient_Search_PartialPPAF.rejected, (state, action) => {
            // state.popUpData = [];
        });
        builder.addCase(searchPatientSurvey.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.patientSurvey.eligiableProduct = action.payload.data.data;
                state.patientSurvey.questionSequence = 1;
            }
        });
        builder.addCase(searchPatientSurvey.pending, (state, action) => {
            state.patientSurvey.eligiableProduct = {};
        });
        builder.addCase(searchPatientSurvey.rejected, (state, action) => {
            state.errorValidation = { message: 'API failed', errorMsg: '' };
        });
        builder.addCase(patientEligibilityProduct.fulfilled, (state, action) => {
            if (action?.payload?.status === 200) {
                const response = action.payload.data.data;
                state.patientSurvey.questionSequence = 1;
                state.patientSurvey.selectedSurveyProduct = response;
                state.patientSurvey.updatedSurveyPayload = {
                    ...response,
                    PatientSurvey: { ...response.PatientSurvey, QuestionAnswers: [] },
                };
            }
        });
        builder.addCase(patientConductSurvey.pending, (state, action) => {
            state.patientSurvey.conductSurveyResponse = {};
        });
        builder.addCase(patientConductSurvey.rejected, (state, action) => {
            state.errorValidation = { message: 'API failed', errorMsg: '' };
        });
        builder.addCase(patientConductSurvey.fulfilled, (state, action) => {
            if (action?.payload?.status === 200) {
                state.patientSurvey.conductSurveyResponse = action.payload.data.data;
            }
        });
        builder.addCase(patientEligibilityProduct.pending, (state, action) => {
            state.patientSurvey.selectedSurveyProduct = {};
            state.patientSurvey.updatedSurveyPayload = {};
        });
        builder.addCase(patientEligibilityProduct.rejected, (state, action) => {
            state.errorValidation = { message: 'API failed', errorMsg: '' };
        });
        builder.addCase(getActivePharmacy.fulfilled, (state, action) => {
            if (action?.payload?.status === 200) {
                state.activePharmacy = action.payload.data;
            }
        });
        builder.addCase(getActivePharmacy.pending, (state, action) => {
            state.activePharmacy = {};
        });
        builder.addCase(getActivePharmacy.rejected, (state, action) => {
            state.errorValidation = { message: 'API failed', errorMsg: '' };
        });
        builder.addCase(createLogDispense.pending, (state, action) => {
            state.logMultipleDispense.createDispenseData = [];
        });
        builder.addCase(createLogDispense.fulfilled, (state, action) => {
            state.logMultipleDispense.createDispenseData = action.payload?.data?.data;
        });
        builder.addCase(createLogDispense.rejected, (state, action) => {
            state.logMultipleDispense.createDispenseData = [];
        });

        builder.addCase(getAdditionalPatientData.pending, (state, action) => {
            state.patientData = [];
        });
        builder.addCase(getAdditionalPatientData.fulfilled, (state, action) => {
            state.patientData = action.payload?.data?.data;
        });
        builder.addCase(getAdditionalPatientData.rejected, (state, action) => {
            state.patientData = [];
        });
        builder.addCase(getPrescription.pending, (state, action) => {
            state.prescriptionFormDetailsData = {};
        });
        builder.addCase(getPrescription.fulfilled, (state, action) => {
            const patientDTO = state.prescriptionFormData?.patientDto;
            const patientAddress2 =
                patientDTO?.patientLocations[0]?.postalCode?.city +
                ', ' +
                patientDTO?.patientLocations[0]?.postalCode?.state?.stateCode +
                ' ' +
                patientDTO?.patientLocations[0]?.postalCode?.postalCode1;

            if (action?.payload?.status === 200) {
                state.prescriptionFormDetailsData.dateRxNeeded = Date.now();
                state.prescriptionFormDetailsData.firstName = patientDTO?.patientNameFirst || '';
                state.prescriptionFormDetailsData.lastName = patientDTO?.patientNameLast || '';
                state.prescriptionFormDetailsData.dob = patientDTO?.patientDob || '';
                state.prescriptionFormDetailsData.pin = patientDTO?.patientExternalUniqueId || '';
                state.prescriptionFormDetailsData.homeAddress1 = patientDTO?.patientLocations[0]?.addressLine1 || '';
                state.prescriptionFormDetailsData.homeAddress2 = patientAddress2;
                state.prescriptionFormDetailsData.homePhone = patientDTO?.patientLocations[0]?.phoneNumber || '';
                state.prescriptionFormDetailsData.vhaName = action.payload?.data?.data?.vaName || '';
                state.prescriptionFormDetailsData.vhaAddress = action.payload?.data?.data?.vhaShippingAddress1;
                state.prescriptionFormDetailsData.vhaCity = action.payload?.data?.data?.vhaShippingCity || '';
                state.prescriptionFormDetailsData.vhaState =
                    action.payload?.data?.data?.vhaShippingStateId?.stateCode || '';
                state.prescriptionFormDetailsData.vhaZip = action.payload?.data?.data?.vhaShippingPostalCode || '';
                state.prescriptionFormDetailsData.vhaExt = '';
                state.prescriptionFormDetailsData.vhaPharmName = action.payload?.data?.data?.vaPharmacistName || '';
                state.prescriptionFormDetailsData.vhaPharmPhone =
                    action.payload?.data?.data?.vaPharmacistPhoneNumber || null;
                state.prescriptionFormDetailsData.vhaPhaFax = action.payload?.data?.data?.vaPharmacistFaxNumber || null;
                state.prescriptionFormDetailsData.mckAccNo = action.payload?.data?.data?.vhaMckAccountNumber || '';
                state.prescriptionFormDetailsData.shipVHAorDirect = action.payload?.data?.data?.vhaShippingType;
                state.prescriptionFormDetailsData.shipAddress = action.payload?.data?.data?.shippingAddress1 || '';
                state.prescriptionFormDetailsData.shipCity = action.payload?.data?.data?.shippingCity || '';
                state.prescriptionFormDetailsData.shipState =
                    action.payload?.data?.data?.shippingStateId?.stateCode || '';
                state.prescriptionFormDetailsData.shipZip = action.payload?.data?.data?.shippingPostalCode || '';
                state.prescriptionFormDetailsData.shipExt = '';
                state.prescriptionFormDetailsData.shipPhone = action.payload?.data?.data?.shippingPhoneNumber || '';
                state.prescriptionFormDetailsData.authNo = action.payload?.data?.data?.authorizationNumber || '';
                state.prescriptionFormDetailsData.authStatus =
                    action.payload?.data?.data?.commTypeId?.lookupValue || '';
                state.prescriptionFormDetailsData.payloadData = action.payload?.data?.data || {};
            }
        });
        builder.addCase(getPrescription.rejected, (state, action) => {
            state.prescriptionFormDetailsData = {};
        });
        builder.addCase(authorizationSearch.pending, (state, action) => {
            state.prescriptionAuthDetails = {};
        });
        builder.addCase(authorizationSearch.fulfilled, (state, action) => {
            if (action?.payload?.status === 200) {
                if (action.payload?.data?.data?.length > 0) {
                    state.prescriptionAuthDetails.payload = action.payload?.data?.data;
                    state.prescriptionAuthDetails.authNo =
                        action.payload?.data?.data[0].authorizationNumber?.substring(2) || '';
                    state.prescriptionAuthDetails.authStatus = action.payload?.data?.data[0].authorizationStatus || '';
                }
            }
        });
        builder.addCase(authorizationSearch.rejected, (state, action) => {
            state.prescriptionAuthDetails = {};
        });
        
        
    },
});

export const {
    resetUserAcc,
    changeProgram,
    resetProgram,
    selectedPatientProgram,
    patientProgramList,
    patientChecklist,
    changeHelpPage,
    handlePrescriber,
    setAccFormDetails,
    setAccountPwdDetails,
    resetApp,
    resetAccValidation,
    setProductEnrolmentData,
    showPV0030Msg,
    changeHelpContent,
    changeHelpContentForAccount,
    changePPAF,
    patientInformation,
    patientSearchStore,
    patientPrescriberStore,
    patientCamundaStore,
    patientReactivationStore,
    patientReactivationSpecialStore,
    activePharmacy,
    setPatientData,
    setPatientId,
    setPatientRisk,
    changePrescriberDetails,
    setPPAFContent,
    changeHelpContentKey,
    resetpatientPrescriberStore,
    resetPatientInformation,
    updateSearchPatient,
    setActiveEnrollments,
    PharmacyStore,
    prescriberSurveySearchStore,
    updatePatientSurvey,
    resetPatientSurvey,
    setLoginPage,
    setPharmacyAuthenticate,
    setLogMultipleDispense,
    resetLogMultipleDispense,
    setPrescriptionFormData,
    deletePrescriptionFormDetailsData,
    setPrescriptionFormDetailsData,
    clearPharmacyLocation,
    setPrescriptionDosage,
    storePrescriptionData,
    storeActionType,
    storeCalenderActionitemInfo,
    setAssociationData,
    setPrescriberEnrollment,
    deletePrescriptionDosage,
    deletePrescriptionAuthDetails,
    updateSelectedPatient,
    clearSelectedPatient,
    setPrescriptionPaylod,
    deleteCalendarData,
    setViewCalendarData,
    setActionItemInfo,
    setPrescriberDashboardCalenderDynamicData,
    setPrescriberCalendarActionItemPayload,
    setSurveyDataFromPatientDetails,
    setSurveyInfoFromRoute,
    resetDispenseHistory,
    setIsWritePrescription,
    deleteIsWritePrescription,
    setLoginHomePrescriber,
    setLoginHomePharmacy,
    resetAppInfo,
    setPatientIntent,
    resetPatientEnrollmentInfo,
    setPatientSignedPPAF,
    setPartialPatientPPafFlow,
} = appSlice.actions;
export default appSlice.reducer;
