import { useEffect,useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import indexedDBStore from 'store/indexedDBStore';
import { resetApp, resetAppInfo } from 'store/slices/appSlice';
import { setLogOut } from 'store/slices/loginSlice';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [showChild, setShowChild] = useState(false);
    const { IsAuthenticated,IsAuthenticatedPharmacy } = useAppSelector((state) => state.login);
    const { loginHomePrescriber, loginHomePharmacy } = useAppSelector((state) => state.app);
    
    const isTokenValid = (token) => {
        try {
            const decoded = jwtDecode(token);
            const currentTime = Math.floor(Date.now()/1000);
            return decoded.exp > currentTime;
        } catch(err){
            console.log("err", err)
            return false
        }
    }

    const checkSession = async() => {
        const loginState = await indexedDBStore.getItem(`${location.pathname.split('/')[1]}User`);
        if(loginState?.currentUser?.accessToken){
            if(isTokenValid(loginState?.currentUser?.accessToken)){
                return loginState?.currentUser?.accessToken
            }else{
                return false;
            }
        }else {
            return false
        }
    }
    const urlToBeOmittedPrescriber = [

        "/prescriber/createaccount",
        "/prescriber/createaccountconfirmation",
        "/prescriber/Home",
        "/prescriber/productRegistration",
        "/prescriber/productSignUp",
        "/prescriber/prescriberSelection",
        "/prescriber/forgotPassword",
        "/prescriber/securityVerification",
        "/prescriber/forgotPasswordConfirmation",
        "/prescriber/resetSecurity",
        "/prescriber/resetTempPassword",
        "/prescriber/resetPasswordConfirmation",
        "/prescriber/patientSurvey",
        "/prescriber/SystemError"
    ];
    const urlToBeOmittedPharmacy = [
        
        "/pharmacy/createAccount",
        "/pharmacy/CreateAccountConfirmation",
        "/pharmacy/pharmacyForgot",
        "/pharmacy/pharmacySecurity",
        "/pharmacy/pharmacyConfirmation",
        "/pharmacy/pharmacyResetTemp",
        "/pharmacy/pharmacyResetConfirmation",
        "/pharmacy/pharmacyResetSecurity",
        "/pharmacy/SystemError"
    ];
    useEffect(() => {
        const checkactiveSession = async() => {
        if (IsAuthenticated && await checkSession()) {
          
            if (['/prescriber','/prescriber/'].includes(location.pathname)) {
                navigate('/prescriber/LoginHome', { replace: true });
            }
            else if(location.pathname.includes('/prescriber') && !loginHomePrescriber && !(urlToBeOmittedPrescriber.some((api) => location.pathname?.toLowerCase().includes(api.toLowerCase()))))
            {   dispatch(resetAppInfo());
                navigate('/prescriber/LoginHome', { replace: true });

            }
             else {
                navigate(location.pathname,{state:location.state});
            }
        } 
        else if(IsAuthenticatedPharmacy && await checkSession()){
            if (['/pharmacy','/pharmacy/'].includes(location.pathname)) {
                navigate('/pharmacy/PharmacyHome', { replace: true });
            }
            else if(location.pathname.includes('/pharmacy') && !loginHomePharmacy && !(urlToBeOmittedPharmacy.some((api) => location.pathname?.toLowerCase().includes(api.toLowerCase()))))
                {   dispatch(resetAppInfo());
                    navigate('/pharmacy/PharmacyHome', { replace: true });
    
                }
             else {
                navigate(location.pathname,{state:location.state});
            }
        }
        else {
           
            if (!(urlToBeOmittedPrescriber.some((api) => location.pathname?.toLowerCase().includes(api.toLowerCase()))) && location.pathname.includes('/prescriber')) {                
                dispatch(resetApp());
		        dispatch(setLogOut());
                indexedDBStore.removeItem(`prescriberUser`);                
                navigate('/prescriber/login', { replace: true });
            } else if (!(urlToBeOmittedPharmacy.some((api) => location.pathname?.toLowerCase().includes(api.toLowerCase()))) && location.pathname.includes('/pharmacy')) {
                indexedDBStore.removeItem(`pharmacyUser`);   
                navigate('/pharmacy/login', { replace: true });
                dispatch(resetApp());
		        dispatch(setLogOut());
            }
        }
        setShowChild(true);
    }
    checkactiveSession()
    }, [location.pathname]);

    return showChild ? children : <></>;
};

export default ProtectedRoute;
