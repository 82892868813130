

const PatientReqBody = {
    modifiedBy: null,

    modifiedDate: null,

    createdBy: null,

    createdDate: null,

    channelId: 'ext',

    camundaProcess: {
        processInstanceId: null,

        currentStepValue: null,

        action: 'Save',
    },

    patientId: null,

    patientNameFirst: '',

    patientNameLast: '',

    patientMiddleInitial: null,

    patientDob: '',

    patientExternalUniqueId: '',

    patientGender: '',

    oldRiskCategory: '',

    language: {
        languageId: null,

        iso_639_1_code: null,

        languageName: 'English',
    },

    inUseBy: null,

    lockExpirationDate: null,

    country: {
        countryId: 1,

        countryName: 'US',

        countryCode: 'US',
    },

    deactivationReason: {
        reasonForChangeId: null,

        reasonForChangeText: null,

        deactivationReasonId: null,

        reasonForChange: null,
    },

    deactivationDate: null,

    attribute1: null,

    attribute2: null,

    attribute3: null,

    attribute4: null,

    attribute5: null,

    attribute6: null,

    attribute7: null,

    attribute8: null,

    attribute9: null,

    attribute10: null,

    patientNameFirstInitial: null,

    patientNameLastInitial: null,

    consentNotification: null,

    consentNotificationSms: null,

    consentNotificationEmail: null,
    patientRegistrationForms: [
        {
            modifiedBy: null,
            modifiedDate: null,
            createdBy: null,
            createdDate: null,
            channelId: null,
            patientId: null,
            productCountry: {
                productCountryId: null,
                riskManagementProgram: null,
                riskManagementIndicator: null,
                dispenseHoldCheck: null,
                distributionType: null,
                productSiteUrl: null,
                product: {
                    productId: null,
                    productName: null,

                    productCode: null,
                    productTradeName: null,
                    productGenericName: null,
                    comsOpProductId: null,
                    deactivationDate: null,
                    productGroup: null,
                    productStatus: null,
                },
            },
            patientRegistrationFormSeq: null,
            dateEffective: null,
            dateEnded: null,
            enrollmentFormImagePath: null,
            faxCommType: null,
            imageRepositoryPath: null,
            initialCreateDate: null,
            initialCreator: null,
            language: {
                languageId: null,
                iso_639_1_code: 'en',
                languageName: 'English',
            },
            optinPatientMaterials: null,
            patMaterialConfirmation: null,
            patMaterialDeliveryStatus: null,
            patientRegImagePath: null,
            patientRegistrationImageId: null,
            patientSignatureDate: null,
            prescriberSignatureDate: null,
            registrationFormRecdDate: null,
            patientRegStatus: [
                {
                    patientId: null,
                    productCountryId: null,
                    patientRegistrationFormSeq: null,
                    dateEffective: null,
                    registrationStatus: {
                        lookupId: null,
                        lookupCategory: null,
                        lookupCode: null,
                        lookupValue: null,
                    },
                    callReason: {
                        lookupId: null,
                        lookupCategory: null,
                        lookupCode: null,
                        lookupValue: null,
                    },
                    reasonForChange: {
                        lookupId: null,
                        lookupCategory: null,
                        lookupCode: null,
                        lookupValue: null,
                    },
                    callComments: null,
                    patientDeactivationComments: null,
                    deactivateReasonChange: {
                        lookupId: null,
                        lookupCategory: null,
                        lookupCode: null,
                        lookupValue: null,
                    },
                    deactivationDate: null,
                    patientReactivationComments: null,
                    reactivateReasonChange: {
                        lookupId: null,
                        lookupCategory: null,
                        lookupCode: null,
                        lookupValue: null,
                    },
                    reactivationDate: null,
                    forced: null,
                    recoveredFrom: null,
                    attemptedVerificationBy: null,
                    verifiedBy: null,
                    verifiedDate: null,
                    stepsQueueWakeDate: null,
                    stepsFlag1: null,
                    stepsFlag2: null,
                    stepsFlag3: null,
                    stepsFlag4: null,
                    registrationFlag: null,
                    registrationDate: null,
                    initialRegistrationDate: null,
                    patientCommTypeId: null,
                },
            ],
        },
    ],

    // regStatus: {
    //     patientId: null,

    //     productCountryId: null,

    //     regFormSeq: null,

    //     dateEffective: null,

    //     registrationStatus: {
    //         lookupId: null,

    //         lookupCategory: null,

    //         lookupCode: null,

    //         lookupValue: null,
    //     },

    //     reasonForChangeId: {
    //         reasonForChangeId: null,

    //         reasonForChangeText: null,

    //         deactivationReasonId: null,

    //         reasonForChange: null,
    //     },

    //     callReason: {
    //         lookupId: null,

    //         lookupCategory: null,

    //         lookupCode: null,

    //         lookupValue: null,
    //     },

    //     callComments: null,

    //     deactivationComments: null,

    //     deactivateReasonChange: {
    //         reasonForChangeId: null,

    //         reasonForChangeText: null,

    //         deactivationReasonId: null,

    //         reasonForChange: null,
    //     },

    //     deactivationDate: null,

    //     reactivationComments: null,

    //     reactivateReasonChange: {
    //         reasonForChangeId: null,

    //         reasonForChangeText: null,

    //         deactivationReasonId: null,

    //         reasonForChange: null,
    //     },

    //     reactivationDate: null,

    //     forced: null,

    //     recoveredFrom: null,

    //     attemptedVerificationBy: null,

    //     verifiedBy: null,

    //     verifiedDate: null,

    //     stepsQueueWakeDate: null,

    //     stepsFlag1: null,

    //     stepsFlag2: null,

    //     stepsFlag3: null,

    //     stepsFlag4: null,

    //     registrationFlag: null,

    //     registrationDate: null,

    //     initialRegistrationDate: null,

    //     patientCommTypeId: null,
    // },

    patientLocations: [
        {
            modifiedBy: null,

            modifiedDate: null,

            createdBy: null,

            createdDate: null,

            channelId: null,

            camundaProcess: {
                processInstanceId: null,

                currentStepValue: null,
            },

            patientId: null,

            patientLocationSeq: null,

            locationActivationDate: null,

            locationDeactivationDate: null,

            addressLine1: '',

            addressLine2: null,

            postalCode: {
                postalCodeId: null,

                postalCode1: '',

                city: '',

                state: {
                    stateId: null,

                    stateCode: null,
                },
            },

            locationVerified: null,

            patientCityNotVerified: null,

            stateIdNotVerified: null,

            postalCode1NotVerified: null,

            postalCode2NotVerified: null,

            postalCode3NotVerified: null,

            mobileNumber: null,
            phoneNumber: null
        },
    ],

    patientPrescribers: [
        {
            modifiedBy: null,

            modifiedDate: null,

            createdBy: null,

            createdDate: null,

            channelId: null,

            camundaProcess: {
                processInstanceId: null,

                currentStepValue: null,
            },

            patientId: null,

            productCountryId: null,

            prescriberId: null,

            prescriberLocationSeq: null,

            patientPrescriberSeq: null,

            startDate: null,

            endDate: null,
        },
    ],

    patientConditions: [
        {
            modifiedBy: null,

            modifiedDate: null,

            createdBy: null,

            createdDate: null,

            channelId: null,

            camundaProcess: {
                processInstanceId: null,

                currentStepValue: null,
            },

            patientConditionId: {
                patientId: null,

                productCountryId: null,

                conditionId: null,
            },

            patientConditionStartDate: null,

            patientConditionEndDate: null,

            otherDiagnosisCode: null,

            otherDiagnosisText: null,

            diagnosisCode: null,
        },
    ],

    notifyByEmail: true,

    notifyByFax: true,

    productType: [''],

    // patientRiskCategory: {
    //     gender: 'Male',

    //     riskCategory: 'Male Child',
    // },
    patientRiskCategory: [
        {
            channelId: null,
            riskCategoryId: {
                riskCategoryId: null,
                productCountryId: null,
                riskCategorySeq: null,
            },
            endDate: null,
            reasonForChangeId: null,
            updateComments: null,
            riskCategoryName: '',
        },
    ],
    patientRiskCriteria: [
        {
            channelId: null,
            riskCriteriaId: {
                riskCriteriaId: 243,
                productCountryId: null,
            },
            riskCriteriaValue: 'N',
        },
        {
            channelId: null,
            riskCriteriaId: {
                riskCriteriaId: 242,
                productCountryId: null,
            },
            riskCriteriaValue: 'N',
        },
        {
            channelId: null,
            riskCriteriaId: {
                riskCriteriaId: 241,
                productCountryId: null,
            },
            riskCriteriaValue: 'N',
        },
    ],
    consentStatementIds: [],
    ppafSignSecurity: {
        modifiedBy: null,
        modifiedDate: null,
        createdBy: null,
        createdDate: null,
        channelId: null,
        camundaProcess: {
            processInstanceId: null,
            currentStepValue: null,
            action: null,
        },
        signatureId: null,
        patientId: null,
        fileId: null,
        ppafId: null,
        signedByFirstName: null,
        signedByLastName: null,
        isSignedByPatient: null,
        reasonForChange: null,
        optinPatientMaterials: null,
        patientRelation: null,
    },
    intent: 'ext_new'
};

export const getPatientReqBody = (obj) => {
    PatientReqBody.consentStatementIds = obj?.consentStatementId || [];
    PatientReqBody.language.languageId = obj?.languageId || null;
    PatientReqBody.locale = obj?.locale || "en";
    PatientReqBody.ppafSignSecurity.signedByFirstName = obj?.signFirstName || null;
    PatientReqBody.ppafSignSecurity.optinPatientMaterials = obj?.optinPatientMaterials ? 'Y' : 'N';
    PatientReqBody.ppafSignSecurity.signedByLastName = obj?.signLastName || null;
    PatientReqBody.ppafSignSecurity.patientRelation = obj?.relation || null;
    PatientReqBody.ppafSignSecurity.isSignedByPatient = obj?.relation ? 'Y':null;
    PatientReqBody.ppafSignSecurity.modifiedDate = obj?.relation ? new Date() :null;
    PatientReqBody.ppafSignSecurity.patientId = obj.patientId || null;
    PatientReqBody.patientId = obj.patientId || null;
    PatientReqBody.oldRiskCategory = obj.oldRiskCategory || null;
    PatientReqBody.patientNameFirst = obj.firstName.trim() || null;
    PatientReqBody.patientNameLast = obj.lastName.trim() || null;
    PatientReqBody.patientMiddleInitial = obj.mi || null;
    PatientReqBody.patientDob = obj.dob || null;
    PatientReqBody.camundaProcess.action = obj.action || PatientReqBody.camundaProcess.action;
    PatientReqBody.camundaProcess.processInstanceId = obj.processInstanceId || null;
    PatientReqBody.patientExternalUniqueId = obj.patientIdNum || null;
    PatientReqBody.patientGender = obj.sex.charAt(0) === 'F' ? 'F' : 'M' || null;
    PatientReqBody.patientLocations[0].patientId = obj.patientId || null;
    PatientReqBody.patientLocations[0].patientLocationSeq = obj.patientLocationSeq || null;
    PatientReqBody.patientLocations[0].addressLine1 = obj.address || null;
    PatientReqBody.patientLocations[0].postalCode.postalCode1 = obj.zip || null;
    PatientReqBody.patientLocations[0].postalCode.city = obj.city || null;
    PatientReqBody.patientLocations[0].postalCode.state.stateCode = obj.state || null;
    PatientReqBody.patientLocations[0].phoneNumber = obj.phone || null;
    PatientReqBody.patientPrescribers[0].patientId = obj.patientId || null;
    PatientReqBody.patientPrescribers[0].patientPrescriberSeq = obj.patientPrescriberSeq || null;
    PatientReqBody.patientPrescribers[0].prescriberLocationSeq = obj.prescriberLocationSeq || null;
    PatientReqBody.patientPrescribers[0].productCountryId = obj.productCountryId || null;
    PatientReqBody.patientPrescribers[0].prescriberId = obj.prescriberExtUniqueId || null;
    PatientReqBody.productType[0] = obj.productType || null;
    //PatientReqBody.patientRiskCategory.gender = obj.sex || null;
    PatientReqBody.patientRiskCategory[0].riskCategoryName = obj.newRisk || null;
    PatientReqBody.patientRiskCategory[0].riskCategoryId.riskCategoryId = obj.riskCategoryId || null;
    PatientReqBody.patientRiskCategory[0].riskCategoryId.productCountryId = obj.productCountryId || null;
    PatientReqBody.patientRiskCategory[0].riskCategoryId.riskCategorySeq = obj.riskCategorySeq || null;

    PatientReqBody.patientRiskCriteria.find((i) => i.riskCriteriaId.riskCriteriaId === 243).riskCriteriaValue =
        obj.natural || null;
    PatientReqBody.patientRiskCriteria.find((i) => i.riskCriteriaId.riskCriteriaId === 242).riskCriteriaValue =
        obj.surgical || null;
    PatientReqBody.patientRiskCriteria.find((i) => i.riskCriteriaId.riskCriteriaId === 241).riskCriteriaValue =
        obj.menstrating || null;

    PatientReqBody.patientRiskCriteria.find(
        (i) => i.riskCriteriaId.riskCriteriaId === 243
    ).riskCriteriaId.productCountryId = obj.productCountryId || null;
    PatientReqBody.patientRiskCriteria.find(
        (i) => i.riskCriteriaId.riskCriteriaId === 242
    ).riskCriteriaId.productCountryId = obj.productCountryId || null;
    PatientReqBody.patientRiskCriteria.find(
        (i) => i.riskCriteriaId.riskCriteriaId === 241
    ).riskCriteriaId.productCountryId = obj.productCountryId || null;

    // PatientReqBody.regStatus.patientId = obj.patientId || null;
    // PatientReqBody.regStatus.productCountryId = obj.productCountryId || null;

    PatientReqBody.patientRegistrationForms[0].productCountry.product.productName=obj.productType || null;
    PatientReqBody.patientRegistrationForms[0].productCountry.productCountryId=obj.productCountryId || null;

    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].patientId=obj.patientId || null;
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].patientRegistrationFormSeq=obj.patientRegistrationFormSeq || null;
    PatientReqBody.patientRegistrationForms[0].patientId=obj.patientId || null;
    PatientReqBody.patientRegistrationForms[0].patientRegistrationFormSeq=obj.patientRegistrationFormSeq || null;
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].productCountryId=obj.productCountryId || null;

    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].registrationStatus.lookupId=obj.statusLookupId || null;
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].registrationStatus.lookupCategory= "registration_status";   
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].registrationStatus.lookupValue = "Active";
    
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].callReason.lookupId=obj.reasonLookupId || null;
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].reasonForChange.lookupId=obj.changeLookupId || null;
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].deactivateReasonChange.lookupId=obj.deactivateLookupId || null;
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].reactivateReasonChange.lookupId=obj.reactivateLookupId || null;
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].registrationStatusId=obj.registrationStatusId || null;
    PatientReqBody.patientRegistrationForms[0].patientRegStatus[0].deactivateReasonChangeId=obj.deactivateReasonChangeId || null;
    PatientReqBody.patientRegistrationForms[0].language.languageId=obj.languageId || null;

    PatientReqBody.patientConditions[0].patientConditionId.patientId = obj.patientId || null;
    PatientReqBody.patientConditions[0].patientConditionId.conditionId = obj.conditionId || null;
    PatientReqBody.patientConditions[0].patientConditionId.productCountryId = obj.productCountryId || null;
    PatientReqBody.patientConditions[0].diagnosisCode = obj.diagnosis?.split('-')[0] || null;
    PatientReqBody.patientConditions[0].otherDiagnosisCode = obj.diagnosis?.split('-')[0] === '000' ? obj.diagnosis?.split('-')[1] : null;
    PatientReqBody.patientConditions[0].otherDiagnosisText =
        obj.diagnosis?.split('-')[0] !== '000' ? obj.diagnosis?.split('-')[1] : obj.otherDiag || null;
    PatientReqBody.intent= obj.intent || PatientReqBody.intent;

    return PatientReqBody;
};
