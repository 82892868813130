import React, { useRef, useState } from 'react';
import classes from './enrollmentModal.module.scss';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    message: string;
    handleContinue: () => void;
    handleCancel: () => void;
    pendingPPAF?: boolean;
}

const EnrollmentModal: React.FC<ModalProps> = ({ handleContinue, handleCancel, message, pendingPPAF = false }) => {
    const modalRef = useRef<HTMLDialogElement | null>(null);
    const { t } = useTranslation();

    return (
        <div className={classes.modalContainer}>
            <dialog ref={modalRef} className={classes.mainContainer}>
                <div className={classes.whiteInfoContainer}>
                    <div className={classes.bigPad}>
                        {pendingPPAF ? (
                            <b>
                                <div>{t('pendingPPAF')}</div>
                            </b>
                        ) : (
                            <>
                                {' '}
                                <b>
                                    <div>
                                        You are not certified for{' '}
                                        <span dangerouslySetInnerHTML={{ __html: message }}></span>{' '}
                                    </div>
                                    <div>Would you like to enroll now?</div>
                                </b>
                                <p>
                                    {' '}
                                    <b>
                                        {' '}
                                        Go to <span dangerouslySetInnerHTML={{ __html: message }}></span> enrollment
                                        page.
                                    </b>
                                </p>
                            </>
                        )}
                    </div>
                    <div className={classes.btnContainer}>
                        <Button
                            clickFunction={handleContinue}
                            className="buttonStyles"
                            type="text"
                            width={130}
                            height={39}
                            size={18}
                            buttonname={pendingPPAF ? t('yesLabel') : "Continue"}
                        />
                        <Button
                            clickFunction={handleCancel}
                            className="buttonStyles"
                            type="text"
                            width={130}
                            height={39}
                            size={18}
                            buttonname={pendingPPAF ? t('noLabel'): "Cancel"}
                        />
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default EnrollmentModal;
