import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { activePharmacy, getBasePharmacy, handlePrescriber, resetLogMultipleDispense, setAccFormDetails, setLogMultipleDispense, setLoginHomePharmacy } from 'store/slices/appSlice';
import ECCP from '../../../assets/images/btn_ECCP.png';
import LogDispense from '../../../assets/images/btn_Log_Dispense.png';
import ManageAcc from '../../../assets/images/btn_Manage_Account.png';
import ReviewAuth from '../../../assets/images/btn_Review_Authorization.png';
import DynamicPopUp from '../Popup/DynamicPopUp';
import { LogDispenseScreens } from '../logDispense/logDispenseType';
import indexedDBStore from 'store/indexedDBStore';
import { setaccessCodeFlag, updateActiveSessionAuth } from 'store/slices/loginSlice';
import classes from './PharmacyHome.module.scss'
import { PROGRAMS } from 'constants/Config/programsConfig';
import { Role, RoleByGroup } from 'constants/Config/appConfig';
let prgms :any =[];
const getPrograms = ()=>{
    PROGRAMS.forEach((i)=>{
        prgms.push({
            id:i.id,
            link:i.link,
            href:i.link,
            program:i.title,
            trademark:i.registeredTradermark
        })
    })
    return prgms;
}
const programsContent = getPrograms();

const images = [
    { id: 'LogDispense', img: LogDispense },
    { id: 'ReviewAuth', img: ReviewAuth },
    { id: 'ManageAcc', img: ManageAcc },
    { id: 'ECCP', img: ECCP },
];

const urlNavigate = (item) => {
    window.open(
        `https://${item.href}`,
        item.id,
        'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width=' +
            window.screen.availWidth * (11 / 12) +
            ',height=600'
    );
};

const PharmacyHome = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { currentUserPharmacy,currentUser,IsAuthenticatedPharmacy } = useAppSelector((state) => state.login);
    const {logMultipleDispense}= useAppSelector((state) => state.app);
    useEffect(() => {
        dispatch(setLoginHomePharmacy(true));
        if (currentUserPharmacy?.userStatus === 'FORCE_CHANGE_PASSWORD') {
            navigate('/pharmacy/pharmacyResetSecurity', { replace: true });
            return;
        }
          //check is session is active
          const checkActiveSession = async()=>{
            const activeUser =await indexedDBStore.getItem('pharmacyUser')
            
            if(activeUser?.currentUser?.accessToken ){
               
                dispatch(updateActiveSessionAuth({data:activeUser?.currentUser,role:"pharmacy" }))
                //dispatch(setaccessCodeFlag(true));
                dispatch(activePharmacy(activeUser?.activePharmacy))
                if (!currentUserPharmacy?.accessToken) {
                    updateUserinfo(activeUser?.currentUser);
                }
            }
        }
      
        checkActiveSession();
    }, []);

   

    const updateUserinfo = (currentUser) => {
       
        const storeData = {
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            officeAddress: currentUser?.userinfo?.address?.formatted,
            city: currentUser?.userinfo['custom:city'],
            state: currentUser?.userinfo['custom:state'],
            zipCode: currentUser?.userinfo['custom:zip_code'],
            phoneNumber: currentUser?.userinfo?.phone_number
                ? currentUser?.userinfo?.phone_number?.replace(/^\+1\s*|\s*/g, '')
                : null,
            email: currentUser?.userinfo?.email,
            userName: currentUser?.userinfo?.username,
            securityQuestion: currentUser?.userinfo['custom:security_question'],
            securityAnswer: currentUser?.userinfo['custom:security_answer'],
        };

        sessionStorage.setItem('accountData', JSON.stringify(storeData));
        dispatch(setAccFormDetails(storeData));
       
         if (currentUser.groupTag?.toLowerCase()?.includes('pharmacist')){
            dispatch(handlePrescriber(Role.Pharmacist));
        }
    };
    const onClickLogDispense=()=>{
        dispatch(resetLogMultipleDispense())
        dispatch(getBasePharmacy(currentUserPharmacy.onlineUserId)).then((response:any)=>
            {                
                if(response?.payload?.response?.status === 400 || response?.payload?.data?.statusCode === 404){   
                    dispatch(
                        setLogMultipleDispense({
                            ...logMultipleDispense,
                            localNavigation: LogDispenseScreens.DefaultLocation,
                        })
                    ); 
                }
                navigate('/pharmacy/logdispensehome');
                
            }
        )
    }
    

    const handleClick = (id) => {

        switch (id) {
            case 'LogDispense':
                onClickLogDispense();
                break;
            case 'ManageAcc':
                navigate('/pharmacy/manageAccount');
                break;
            case 'ReviewAuth':
                navigate('/pharmacy/reviewAuthorization');
                break;
            default:
                navigate('/pharmacy/educationAndCounseling');
                break;
        }
    };

    return (
        <div style={{ marginTop: '10px' }} className={classes.pharmacyHome}>
            <DynamicPopUp/>
          { currentUserPharmacy?.userStatus === 'CONFIRMED' && <PageBackground label="Home">
                <>
                    <SessionTimeout />
                    <div className={classes.contentContainer}>
                        <div className={classes.contentPadding}>
                            <div className={classes.prgmContainer}>
                                {programsContent.map((item) => (
                                    <div key={item.id} className={classes.prgm}>
                                        Visit{' '}
                                        <span tabIndex={0} onClick={() => urlNavigate(item)}>
                                            {item.link}
                                        </span>
                                        , to
                                        <br />
                                        learn more about the {item.program}
                                        {
                                        /* ['thal'].includes(item.id) */
                                        item?.trademark ? (
                                            <>
                                                <sup>&reg;</sup>
                                            </>
                                        ) : (
                                            ''
                                        ) 
                                        
                                        }{' '}
                                        program.
                                    </div>
                                ))}
                            </div>
                            <div>
                                <p>
                                    The Pharmacy Portal for Bristol Myers Squibb-administered REMS programs is an
                                    alternate resource that provides pharmacies the ability to log dispenses and receive
                                    Confirmation Numbers. The Log Dispense function allows for up to 20 dispenses to be
                                    logged at a time. The Review Authorization function allows access to detailed
                                    prescription information.
                                </p>
                                <p>
                                    Select the "Manage My Account" button to view your Pharmacy Portal for Bristol Myers
                                    Squibb-administered REMS programs online account information.
                                </p>
                            </div>
                            <div className={classes.columnFlex}>
                                {images.map((i) => (
                                    <img
                                        className={classes.actCls}
                                        onClick={() => handleClick(i.id)}
                                        key={i.id}
                                        alt={i.id}
                                        src={i.img}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            </PageBackground>
            }
        </div>
    );
};

export default PharmacyHome;
